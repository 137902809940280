import { dateFormat } from "@/utils/util";
export default {
  setup: function setup() {},
  props: {
    item: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    taskdetail: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  methods: {
    dateFormat: dateFormat
  },
  mounted: function mounted() {}
};