var render = function render() {
  var _vm$pageData$conversi;
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("a-card", {
    staticStyle: {
      "overflow-x": "scroll"
    },
    attrs: {
      bordered: false
    }
  }, [_c("div", {
    staticClass: "page-title",
    staticStyle: {
      "margin-bottom": "20px"
    }
  }, [_vm._v("Task Details")]), _c("a-card", {
    staticClass: "module"
  }, [_c("div", {
    staticClass: "title flex"
  }, [_c("span", [_vm._v(_vm._s(_vm.pageData.taskname))]), _c("div", {
    staticStyle: {
      "flex-shrink": "0"
    }
  }, [_c("span", {
    staticClass: "task-id"
  }, [_vm._v(" Task ID: " + _vm._s(_vm.pageData.taskid || "") + " ")]), _c("span", {
    staticClass: "task-id"
  }, [_vm._v(_vm._s(_vm.formatTaskStatusText))])])]), _c("div", {
    staticClass: "content-promotion"
  }, [_c("div", {
    staticClass: "item"
  }, [_c("p", {
    staticClass: "key"
  }, [_vm._v("Advertiser Name")]), _c("p", {
    staticClass: "value"
  }, [_vm._v(_vm._s(_vm.pageData.advertisername))])]), _c("div", {
    staticClass: "item"
  }, [_c("p", {
    staticClass: "key"
  }, [_vm._v("Task Type")]), _c("p", {
    staticClass: "value"
  }, [_vm._v(" " + _vm._s(_vm.pageData.costtype === 0 ? "Cost by Impression" : _vm.pageData.costtype === 1 ? "Cost by click" : "Fixed Price") + " ")])]), _c("div", {
    staticClass: "item"
  }, [_c("p", {
    staticClass: "key"
  }, [_vm._v("Coversion Widget")]), _c("p", {
    staticClass: "value max-w-[170px] overflow-hidden text-ellipsis"
  }, [_vm._v(" " + _vm._s(_vm.pageData.conversionwidget ? "Landing Page Widget" : "N/A") + " ")])]), _c("div", {
    staticClass: "item"
  }, [_c("p", {
    staticClass: "key"
  }, [_vm._v("Total Funding")]), _c("p", {
    staticClass: "value"
  }, [_vm._v(_vm._s(_vm.pageData.totalfunding) + " " + _vm._s(_vm.pageData.tasktoken === 0 ? "KF3" : "USDT"))])]), _c("div", {
    staticClass: "item"
  }, [_c("p", {
    staticClass: "key"
  }, [_vm._v("End Time")]), _c("p", {
    staticClass: "value"
  }, [_vm._v(" " + _vm._s(_vm.dateFormat(_vm.pageData.endtime * 1000, "Y-M-d H:i")) + " ")])])])]), _c("a-tabs", {
    staticClass: "mt-20",
    on: {
      change: _vm.changeHandler
    },
    model: {
      value: _vm.activeTabKey,
      callback: function callback($$v) {
        _vm.activeTabKey = $$v;
      },
      expression: "activeTabKey"
    }
  }, [_c("a-tab-pane", {
    key: 1,
    attrs: {
      tab: "Task Requirement"
    }
  }, [_c("a-card", {
    staticClass: "module"
  }, [_c("p", {
    staticClass: "title"
  }, [_vm._v("Funding and Cost Type")]), _c("div", {
    staticClass: "content-promotion"
  }, [_c("div", {
    staticClass: "item"
  }, [_c("p", {
    staticClass: "key"
  }, [_vm._v("Task Token")]), _c("p", {
    staticClass: "value"
  }, [_vm._v(" " + _vm._s(_vm.pageData.tasktoken === 0 ? "KF3" : "USDT") + " ")])]), _c("div", {
    staticClass: "item"
  }, [_c("p", {
    staticClass: "key"
  }, [_vm._v("Cost Type")]), _c("p", {
    staticClass: "value"
  }, [_vm._v(" " + _vm._s(_vm.pageData.costtype === 0 ? "Cost by Impression" : _vm.pageData.costtype === 1 ? "Cost by click" : "Fixed Price") + " ")])]), !(_vm.pageData.costtype === 2) ? _c("div", {
    staticClass: "item"
  }, [_c("p", {
    staticClass: "key"
  }, [_vm._v("CPM(cost per mille)")]), _c("p", {
    staticClass: "value"
  }, [_vm._v(_vm._s(_vm.pageData.costunitprice) + " " + _vm._s(_vm.pageData.tasktoken === 0 ? "KF3" : "USDT"))])]) : _vm._e(), _c("div", {
    staticClass: "item"
  }, [_c("p", {
    staticClass: "key"
  }, [_vm._v("Total Funding")]), _c("p", {
    staticClass: "value"
  }, [_vm._v(_vm._s(_vm.pageData.totalfunding) + " " + _vm._s(_vm.pageData.tasktoken === 0 ? "KF3" : "USDT"))])])])]), _c("a-card", {
    staticClass: "module mt-20"
  }, [_c("p", {
    staticClass: "title"
  }, [_vm._v("Basic Info")]), _c("div", {
    staticClass: "item-row"
  }, [_c("div", {
    staticClass: "min-200"
  }, [_c("p", [_vm._v("Product Name")]), _c("p", {
    staticClass: "value"
  }, [_vm._v(_vm._s(_vm.pageData.productname))])]), _c("div", {
    staticClass: "min-200"
  }, [_c("p", [_vm._v("Product Industry")]), _c("p", {
    staticClass: "value"
  }, [_vm._v(_vm._s(_vm.pageData.productfield))])]), _c("div", {
    staticClass: "flex flex-col w-100"
  }, [_c("p", [_vm._v("Product Description")]), _c("p", {
    staticClass: "value"
  }, [_vm._v(_vm._s(_vm.pageData.productdesc))])])]), _c("div", {
    staticClass: "task-info mt-20"
  }, [_c("p", {
    staticClass: "label"
  }, [_vm._v("Task Name")]), _c("p", {
    staticClass: "value"
  }, [_vm._v(_vm._s(_vm.pageData.taskname))]), _c("p", {
    staticClass: "label label-icon"
  }, [_vm._v("Task Icon")]), _c("img", {
    staticClass: "icon",
    attrs: {
      src: _vm.pageData.taskicon
    }
  }), _c("p", {
    staticClass: "label mt-20"
  }, [_vm._v("Promotion Date Range")]), _c("p", {
    staticClass: "value"
  }, [_vm._v(" " + _vm._s(_vm.dateFormat(_vm.pageData.starttime * 1000, "Y M d")) + " - " + _vm._s(_vm.dateFormat(_vm.pageData.endtime * 1000, "Y M d")) + " ")])])]), _c("a-card", {
    staticClass: "module mt-20"
  }, [_c("p", {
    staticClass: "title"
  }, [_vm._v("Task Requirement")]), _c("div", {
    staticClass: "item-row"
  }, [_c("p", [_vm._v("Content Requirement")]), _c("div", {
    staticClass: "value flex w-100 flex-col",
    domProps: {
      innerHTML: _vm._s(_vm.pageData.taskrequirementcontent)
    }
  })]), _vm.pageData.videourl ? _c("div", {
    staticClass: "item-row mt-20"
  }, [_c("p", {
    staticClass: "w-100"
  }, [_vm._v("Demo Video")]), _vm.pageData.coverurl ? _c("img", {
    staticClass: "Demo-video",
    attrs: {
      src: _vm.pageData.coverurl
    }
  }) : _c("div", {
    staticClass: "default-cover"
  }, [_c("svg", {
    staticStyle: {
      width: "60px",
      color: "#fff"
    },
    attrs: {
      viewBox: "0 0 80 80",
      fill: "currentColor",
      xmlns: "http://www.w3.org/2000/svg"
    }
  }, [_c("mask", {
    staticStyle: {
      "mask-type": "luminance"
    },
    attrs: {
      id: "mask0_104_42621",
      maskUnits: "userSpaceOnUse",
      x: "0",
      y: "0",
      width: "80",
      height: "80"
    }
  }, [_c("path", {
    attrs: {
      d: "M80 0H0V80H80V0Z"
    }
  })]), _c("g", {
    attrs: {
      mask: "url(#mask0_104_42621)"
    }
  }, [_c("path", {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      d: "M25.6621 11H16.0003V49.2603H25.6264V52.1952L44.8621 37.7133V30.5097H54.336V30.5808L63.9977 23.3068V11H54.336V20.8561H44.8621V11H35.2003V20.8561H25.6621V11ZM63.9977 29.5654L54.336 36.8394V39.606H50.6612L25.6264 58.4538V58.7746H25.2003L16 65.7012V68.4282H35.0704V58.9127H45.1845V68.4282H64V58.7746H54.3533V49.2603H63.9977V29.5654ZM16 59.4426L16.8872 58.7746H16V59.4426ZM35.2003 39.6092H25.6621V30.5097H35.2003V39.6092Z",
      "fill-opacity": "0.4"
    }
  })])])])]) : _vm._e(), _c("div", {
    staticClass: "item-row mt-20"
  }, [_c("p", [_vm._v("Supplementary Description")]), _c("span", {
    staticClass: "value w-100"
  }, [_vm._v(_vm._s(_vm.pageData.requirementdesc))])]), _c("div", {
    staticClass: "item-row"
  }, [_c("p", {
    staticClass: "w-100"
  }, [_vm._v("Conversion Widget Information")]), (_vm$pageData$conversi = _vm.pageData.conversionwidgetJson) !== null && _vm$pageData$conversi !== void 0 && _vm$pageData$conversi.title ? _c("span", {
    staticClass: "value"
  }, [_vm._v(" Landing Page Widget "), _c("br"), _c("span", {
    staticStyle: {
      color: "#333"
    }
  }, [_vm._v("Text：")]), _vm._v(_vm._s(_vm.pageData.conversionwidgetJson.title) + " "), _c("br"), _c("span", {
    staticStyle: {
      color: "#333"
    }
  }, [_vm._v(" Link： ")]), _vm._v(" " + _vm._s(_vm.pageData.conversionwidgetJson.url) + " ")]) : _c("span", {
    staticClass: "value"
  }, [_vm._v("N/A")])])]), _c("a-card", {
    staticClass: "mt-20 module"
  }, [_c("p", {
    staticClass: "title"
  }, [_vm._v("Creator Selection")]), _c("span", {
    staticStyle: {
      color: "#3270f5",
      cursor: "pointer"
    },
    on: {
      click: function click($event) {
        return _vm.toUserDetail(_vm.pageData.advertiseruid);
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.pageData.advertisername) + " ")])]), _c("a-card", {
    staticClass: "module mt-20"
  }, [_c("p", {
    staticClass: "title"
  }, [_vm._v("Payment")]), _c("div", {
    staticClass: "item-row"
  }, [_c("p", {
    staticClass: "w-100 text-hex-#FFFFFF"
  }, [_vm._v("Payment details")]), _c("span", {
    staticClass: "value",
    staticStyle: {
      padding: "2px 8px"
    }
  }, [_vm._v(" " + _vm._s(_vm.pageData.totalfunding) + " "), _c("span", {
    staticClass: "ml-20"
  }, [_vm._v(" " + _vm._s(_vm.pageData.tasktoken === 0 ? "KF3" : "USDT") + " ")])])]), _c("div", {
    staticClass: "item-row"
  }, [_c("p", {
    staticClass: "w-100"
  }, [_vm._v("Payment method")]), _c("span", {
    staticClass: "value"
  }, [_vm._v(" Pay with " + _vm._s(_vm.pageData.paymentmethod === 0 ? "spending wallet" : "on-chian wallet") + " ")])])])], 1), _c("a-tab-pane", {
    key: 2,
    attrs: {
      tab: "Application Videos",
      "force-render": ""
    }
  }, [_c("a-list", {
    staticClass: "video-list",
    attrs: {
      loading: _vm.isloading,
      "data-source": _vm.videoList,
      grid: {
        gutter: 16,
        column: 2
      }
    },
    scopedSlots: _vm._u([{
      key: "renderItem",
      fn: function fn(item, index) {
        return _c("a-list-item", {}, [_c("videoItemVue", {
          attrs: {
            item: item,
            taskdetail: _vm.pageData
          }
        })], 1);
      }
    }])
  }), _c("a-pagination", {
    attrs: {
      total: _vm.total,
      pageSize: _vm.size
    },
    on: {
      change: _vm.pageHandler
    }
  })], 1)], 1), _c("a-form-model", {
    ref: "ruleForm4",
    attrs: {
      rules: _vm.rules2,
      model: _vm.info,
      "label-col": {
        span: 6
      },
      "wrapper-col": {
        span: 14
      }
    }
  }, [_c("a-card", {
    staticClass: "mt-20",
    staticStyle: {
      background: "rgb(240, 242, 245)",
      "border-radius": "20px"
    },
    attrs: {
      title: "Approval"
    }
  }, [_c("a-radio-group", {
    model: {
      value: _vm.pasValue,
      callback: function callback($$v) {
        _vm.pasValue = $$v;
      },
      expression: "pasValue"
    }
  }, [_c("a-space", {
    attrs: {
      direction: "vertical"
    }
  }, [_c("a-radio", {
    attrs: {
      disabled: _vm.info.status != 0,
      value: 1
    }
  }, [_vm._v("Approval")]), _c("a-radio", {
    attrs: {
      disabled: _vm.info.status != 0,
      value: 2
    }
  }, [_vm._v("Reject")]), _c("a-form-model-item", {
    attrs: {
      label: "",
      prop: "rejectMessage"
    }
  }, [_c("a-textarea", {
    staticStyle: {
      width: "500px"
    },
    attrs: {
      disabled: _vm.info.status != 0 || _vm.pasValue != 2,
      size: "large",
      placeholder: "Enter reason for rejection",
      "auto-size": {
        minRows: 3,
        maxRows: 8
      }
    },
    model: {
      value: _vm.rejectMessage,
      callback: function callback($$v) {
        _vm.rejectMessage = $$v;
      },
      expression: "rejectMessage"
    }
  })], 1), _c("a-button", {
    directives: [{
      name: "hasPermission",
      rawName: "v-hasPermission",
      value: ["check:bcTaskDetails:commit"],
      expression: "['check:bcTaskDetails:commit']"
    }],
    attrs: {
      type: "primary",
      disabled: _vm.info.status != 0,
      loading: _vm.loading4
    },
    on: {
      click: function click($event) {
        return _vm.approveAction(_vm.info);
      }
    }
  }, [_vm._v(" Submit ")])], 1)], 1)], 1)], 1)], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };