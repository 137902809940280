var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "video-item"
  }, [_c("div", {
    staticClass: "item-img"
  }, [_vm.item.coverurl ? _c("img", {
    staticStyle: {
      width: "120px",
      "border-radius": "8px",
      height: "120px"
    },
    attrs: {
      src: _vm.item.coverurl
    }
  }) : _c("div", {
    staticClass: "default-cover"
  }, [_c("svg", {
    staticStyle: {
      width: "60px",
      color: "#fff"
    },
    attrs: {
      viewBox: "0 0 80 80",
      fill: "currentColor",
      xmlns: "http://www.w3.org/2000/svg"
    }
  }, [_c("mask", {
    staticStyle: {
      "mask-type": "luminance"
    },
    attrs: {
      id: "mask0_104_42621",
      maskUnits: "userSpaceOnUse",
      x: "0",
      y: "0",
      width: "80",
      height: "80"
    }
  }, [_c("path", {
    attrs: {
      d: "M80 0H0V80H80V0Z"
    }
  })]), _c("g", {
    attrs: {
      mask: "url(#mask0_104_42621)"
    }
  }, [_c("path", {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      d: "M25.6621 11H16.0003V49.2603H25.6264V52.1952L44.8621 37.7133V30.5097H54.336V30.5808L63.9977 23.3068V11H54.336V20.8561H44.8621V11H35.2003V20.8561H25.6621V11ZM63.9977 29.5654L54.336 36.8394V39.606H50.6612L25.6264 58.4538V58.7746H25.2003L16 65.7012V68.4282H35.0704V58.9127H45.1845V68.4282H64V58.7746H54.3533V49.2603H63.9977V29.5654ZM16 59.4426L16.8872 58.7746H16V59.4426ZM35.2003 39.6092H25.6621V30.5097H35.2003V39.6092Z",
      "fill-opacity": "0.4"
    }
  })])])])]), _c("div", {
    staticClass: "ml-20"
  }, [_c("p", {
    staticClass: "item-title"
  }, [_vm._v(" " + _vm._s(_vm.item.knowherevideotitle || _vm.item.videotitle || "") + " ")]), _c("p", [_vm._v(" " + _vm._s(_vm.dateFormat(_vm.item.createdtime * 1000, "M d,Y H:i:s")) + " ")]), _c("div", {
    staticClass: "creator"
  }, [_vm._v(_vm._s(_vm.item.username))])]), _c("div", {
    staticClass: "row-data"
  }, [_c("div", {
    staticClass: "data-item"
  }, [_c("p", {
    staticClass: "value"
  }, [_vm._v(_vm._s(_vm.item.impression))]), _c("p", [_vm._v("Impression")])]), _c("div", {
    staticClass: "data-item"
  }, [_c("p", {
    staticClass: "value"
  }, [_vm._v(_vm._s(_vm.item.validimpression))]), _c("p", [_vm._v("Valid impression")])]), _c("div", {
    staticClass: "data-item"
  }, [_c("p", {
    staticClass: "value"
  }, [_vm._v(_vm._s(_vm.item.costunitprice) + " " + _vm._s(_vm.taskdetail.tasktoken === 0 ? "KF3" : "USDT"))]), _c("p", [_vm._v("CPM")])]), _c("div", {
    staticClass: "data-item"
  }, [_c("p", {
    staticClass: "value"
  }, [_vm._v(_vm._s(_vm.item.reward) + " " + _vm._s(_vm.taskdetail.tasktoken === 0 ? "KF3" : "USDT"))]), _c("p", [_vm._v("Reward")])])])]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };